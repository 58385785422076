import React from "react"

const TermsOfBuybackDocument = () => {
  return (
    <div className="DocumentBlock">
      <p>
        <i>Effective from Feb 07, 2021</i>
      </p>
      <p>
        <b>
          These terms of buyback constitute a legally binding agreement between
          you and Mothership Foundation OÜ (“Mothership”, “us”, “our”) and
          governs the legal relationship between you and Mothership.
        </b>
      </p>
      <p>
        By accepting these terms of buyback you represent and warrant that you:
      </p>
      <ul>
        <li>are aged 18 or over and have full legal capacity;</li>
        <li>have read and agree to be bound by the terms of buyback;</li>
        <li>
          do not use funds that are related to or obtained due to any illegal
          activities;
        </li>
        <li>
          are the owner and sole beneficiary of the funds you transfer to
          Mothership and are acting on your own behalf by doing so;
        </li>
        <li>are legally permitted to transfer and sell the funds to us;</li>
        <li>
          are not citizen or a resident of the Islamic Republic of Iran or
          Democratic People’s Republic of Korea (North Korea);
        </li>
        <li>are not subject to any international sanctions;</li>
        <li>
          shall act in good faith and not use us or our services for any illegal
          activities.
        </li>
      </ul>
      <h2>1. Initiating the buyback</h2>
      <p>
        1.1. You can sell your Mothership ERC20 tokens (contract address:
        0x68AA3F232dA9bdC2343465545794ef3eEa5209BD; <b>“MSP Tokens”</b>) to
        Mothership using the Stakeholder Profile accessible at
        https://mothership.cx/profile (<b>“Platform”</b>). By transferring MSP
        Tokens to Mothership, you also transfer all rights and claims arising or
        related, either directly or indirectly, to the MSP Tokens to Mothership.
      </p>
      <p>1.2. To initiate the buyback you have to:</p>
      <p>1.2.1. verify your identity; </p>
      <p>
        1.2.2. register and verify the addresses from where you will transfer
        the MSP Tokens to Mothership (<b>“Verified Addresses”</b>);
      </p>
      <p>
        1.2.3. register your ETH address where you wish to receive the payment
        for your MSP (<b>“Withdrawal Address”</b>); and
      </p>
      <p>
        1.2.4. transfer your MSP Tokens from the Verified Addresses to our ETH
        address at the Platform (<b>“Deposit Address”</b>).
      </p>
      <p>
        1.3. Once Mothership has received the MSP Tokens to its Deposit Address,
        Mothership will verify the amount of MSP Tokens received from your
        Verified Addresses.{" "}
      </p>
      <h2>2. Transfers to the Deposit Address and misdirected funds</h2>
      <p>
        2.1. We only accept transfers of MSP Tokens to our Deposit Address. We
        do not accept any other cryptocurrencies or cryptographic tokens. Any
        other cryptocurrency or cryptographic tokens transferred to the Deposit
        Address may be lost forever and irrecoverable. We are under no
        obligation to reimburse such lost funds.
      </p>
      <p>
        2.2. We may check the legality of all funds transferred to the Deposit
        Address. We have the right to ask additional questions and documents
        should there be any questions about the legality of those funds (e.g.
        the funds originate from addresses that are known to be associated with
        criminal activities). We will clear those funds once we are certain that
        the funds are legal. Under certain situations, we may be obligated to
        freeze the assets received and retain any funds pending further
        investigation. You acknowledge and agree that we will not pay to you any
        interest on any funds held by us.
      </p>
      <p>
        2.3. You are obligated to ensure that you send your funds to correct
        Deposit Address. If you send funds to a wrong address, such funds will
        be lost and are irrecoverable. We are under no obligation to reimburse
        such lost funds.
      </p>
      <h2>3. Payment</h2>
      <p>
        3.1. Mothership shall make you a payment (<b>“Payment”</b>) for the MSP
        Tokens you have sold to us.
      </p>
      <p>
        3.2. The Payment will be transferred to your Withdrawal address in ERC
        20 token Tether USD (contract address:
        0xdac17f958d2ee523a2206206994597c13d831ec7; <b>“USDT”</b>) and will be
        the sum of following:
      </p>
      <p>
        3.2.1. MSP Tokens received on the Deposit Address from the Verified
        Addresses multiplied by 0.0102 USDT;
      </p>
      <p>
        3.2.2. The number of Verified Addresses from where you transferred MSP
        Tokens to the Deposit Address multiplied by the average MSP transfer fee
        of the day (based on time zone UTC +2) we received the MSP Tokens (but
        not more than 60 USDT / Verified Address).
      </p>
      <p>
        3.3. Mothership will cover the transaction fees for the Payment.
        Mothership will transfer the Payment to your Withdrawal Address on the
        next business day after we received MSP Tokens from the Verified
        Addresses. If you transfer MSP Tokens to the Deposit Address before you
        have registered the addresses as Verified Addresses, your deposit may
        not be recognised on time which may cause the Payment to be paid out
        later.
      </p>
      <h2>4. Taxes and Limitation of Liability</h2>
      <p>
        4.1. You are responsible for determining what, if any, taxes apply to
        receipt of the Payment from Mothership and otherwise in connection with
        this agreement. It is your responsibility to withhold, collect, report
        and remit the correct taxes to the appropriate tax authorities.
        Mothership is not responsible for withholding, collecting, reporting, or
        remitting any taxes or duties arising from the Payment to you.{" "}
      </p>
      <p>
        4.2. Cryptocurrency transfers are made on blockchain and may incur
        delays in transmission and receipt of funds. In particular, you
        acknowledge that we operate an online service that could be subject to
        technical or other problems, the nature and duration of which may be
        beyond our control. While we do everything reasonably within our power
        to ensure the timely transmission of funds, we cannot guarantee that
        transfers will always be received on time and cannot accept any
        liability to you or any other person for any loss suffered, directly or
        indirectly, as a result of any delays in the completion of a
        transaction. We will be under no obligation to inform you of any delay
        that may apply to your transaction, however in this event we will
        endeavour to process your transaction at the earliest opportunity.
      </p>
      <p>
        4.3. We do not under any circumstances assume liability to you in excess
        of the amount of MSP Tokens you have transferred to us. We will not be
        liable to you in any form for any consequential damages or loss that you
        may suffer as a result of delay in the transfer of funds or any of our
        obligations under this agreement. You may not make any claim against us
        for but not limited to; loss of business, loss of opportunity or loss of
        interest on funds.
      </p>
      <p>
        4.4. Both parties confirm that once you have completed actions listed in
        section 1 and Mothership has completed actions listed in section 2, they
        will no longer have any outstanding claims against each other nor know
        any facts which may cause such claims.
      </p>
      <h2>5. Miscellaneous</h2>
      <p>
        5.1. The Agreement shall enter into force once you confirm that you
        agree to these terms of buyback on the Platform and will remain in force
        until terminated by you or us or fully executed.
      </p>
      <p>
        5.2. This Agreement constitutes the full and entire understanding and
        agreement between you and us regarding the subjects hereof and
        supersedes any agreement or understanding.
      </p>
      <p>
        5.3. If any provision of this agreement is held to be invalid or
        unenforceable, all other provisions will remain in full force and effect
        and will not in any way be impaired. The parties agree to replace the
        invalid or unenforceable provision by a valid or enforceable provision,
        which shall best reflect the parties’ original intention and shall to
        the maximum extent possible achieve the same economic result.
      </p>
      <p>
        5.4. All notices and other communications made or to be made under this
        agreement shall be made in English in writing or in a form reproducible
        in writing and shall be sent to <b>hello@mothership.cx</b> in case the
        recipient is Mothership or the e-mail address you used for registering
        at the Platform in case of you. Each party may change the addresses by
        giving the other party a notice of the new address in a form
        reproducible in writing.
      </p>
      <p>
        5.5. You accept that Mothership has the right to change the content or
        technical specifications of any aspect of the Platform at any time at
        Mothership’s sole discretion. You further accept that such changes may
        result in you being unable to access the Platform.
      </p>
      <p>
        5.6. These terms and conditions shall be governed and construed in
        accordance with the laws of the Republic of Estonia, without giving
        effect to conflicts-of-law principles thereof. All claims arising out of
        or related to these terms and conditions shall be settled in Harju
        County Court as a court of first instance.
      </p>
    </div>
  )
}

export default TermsOfBuybackDocument
