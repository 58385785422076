import React, { useState } from "react"
import { navigate } from "gatsby"
import * as Sentry from "@sentry/gatsby"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Alert from "@material-ui/lab/Alert"
import { DefaultCheckbox, formStatuses } from "./privacy-policy"
import { functions } from "../../configs/firebase"
import { reportToSentry } from ".."
import { statuses as authStatuses } from "../../hooks/auth"
import { useAppState } from "../../components/provider"
import Layout from "../../components/layout"
import LoaderBlock from "../../components/loader"
import TermsOfBuybackDocument from "../../components/docs/terms-of-buyback"

const Form = ({ onSubmit, tosAcceptedAt, status, isAvailable }) => {
  const [value, setTermsOfService] = useState(!!tosAcceptedAt)

  const onChange = event => {
    setTermsOfService(event.target.checked)
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="CheckboxLabel">
        {status === formStatuses.failed && (
          <Alert severity="error" style={{ marginBottom: "1rem" }}>
            Something went wrong&hellip; Please try submitting it again later.
          </Alert>
        )}
        {tosAcceptedAt ? (
          <p className="Text">
            Terms of Buyback accepted at{" "}
            <strong>{tosAcceptedAt.toDate().toUTCString()}</strong>
          </p>
        ) : !isAvailable ? null : (
          <FormControlLabel
            control={
              <DefaultCheckbox
                name="termOfService"
                onChange={onChange}
                checked={value}
                disabled={status === formStatuses.processing}
              />
            }
            label="I consent with the Terms of Buyback"
          />
        )}
      </div>
      {!isAvailable ? null : (
        <button
          className="Btn primary"
          disabled={!value || status === formStatuses.processing}
        >
          Continue
        </button>
      )}
    </form>
  )
}

const TermsOfService = () => {
  const [formStatus, setFormStatus] = useState(formStatuses.initial)
  const {
    auth: { status: authStatus, user, profile = {} },
  } = useAppState()
  const { buyback } = profile

  const onSubmit = event => {
    event.preventDefault()
    const postTos = functions.httpsCallable("buyback-acceptTOS")
    setFormStatus(formStatuses.processing)
    postTos()
      .then(() => {
        setFormStatus(formStatuses.success)
        navigate("/buyback/verify-identity")
      })
      .catch(err => {
        setFormStatus(formStatuses.failed)
        reportToSentry(err, {
          user: { id: user.uid },
        })
      })
  }

  if (user === null && authStatus === authStatuses.initial) {
    navigate("/")
    return null
  }

  return (
    <Layout>
      <Sentry.ErrorBoundary
        beforeCapture={scope => {
          scope.setUser({ id: profile.uid })
        }}
      >
        <div className="Section">
          <div className="PanelContainer">
            <h1 className="Title">Terms of Buyback</h1>
            <section className="Panel textFormat">
              <TermsOfBuybackDocument />
              {buyback ? (
                <Form
                  onSubmit={onSubmit}
                  tosAcceptedAt={buyback.tosAcceptedAt}
                  status={formStatus}
                  isAvailable={buyback.isAvailable}
                />
              ) : (
                <LoaderBlock isLoading />
              )}
            </section>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    </Layout>
  )
}

export default TermsOfService
